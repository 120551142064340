import React, { useContext } from "react"
import { loadStripe } from "@stripe/stripe-js"
import tw from "twin.macro"
import { css } from "styled-components/macro" //eslint-disable-line

import { taxRateId } from "../data/taxRate"
import { CartContext } from "./CartProvider"
import { PrimaryButton as PrimaryButtonBase } from "./treact/misc/Buttons.js"

const CheckoutButton = tw(PrimaryButtonBase)`text-sm mt-4`

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY)

const Checkout = () => {
  const { cart, shipping } = useContext(CartContext)

  const onClick = () => {
    const line_items = cart.map(([price, quantity, notes]) => ({
      price: price.id,
      quantity,
      description: notes,
      tax_rates: [taxRateId],
    }))
    const body = JSON.stringify({ line_items, shipping_rates: [shipping] })

    fetch("/.netlify/functions/orderCreate", {
      method: "POST",
      body,
    })
      .then(async response => {
        const { id } = await response.json()
        const stripe = await stripePromise
        const { error } = await stripe.redirectToCheckout({ sessionId: id })
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `error.message`.
        alert(error.message)
      })
      .catch(err => alert(err.message))
  }

  return <CheckoutButton onClick={onClick}>Checkout</CheckoutButton>
}

export default Checkout
