import React from "react"
import tw from "twin.macro"
import { css } from "styled-components/macro" //eslint-disable-line

import ChevronDownIcon from "react-feather/dist/icons/chevron-down"
import XIcon from "react-feather/dist/icons/x"

{
  /* <style>
    .top-100 {top: 100%}
    .bottom-100 {bottom: 100%}
    .max-h-select {
        max-height: 300px;
    }
</style> */
}

const Select = ({ label, items, className, ...props }) => {
  const simple = (
    <div tw="flex items-center" className={className}>
      {label && <span tw="mr-3">{label}</span>}
      <div tw="relative">
        <select
          tw="w-full rounded-default border-default appearance-none border-gray-500 py-2 focus:outline-none hocus:(border-primary-500 shadow-outline) text-base pl-3 pr-10"
          {...props}
        >
          {items.map((item, index) => (
            <option key={index} value={item.id}>{item.title}</option>
          ))}
        </select>
        <span tw="absolute right-0 top-0 h-full w-10 text-center text-gray-600 pointer-events-none flex items-center justify-center">
          <ChevronDownIcon size="18" />
          {/* <svg
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            tw="w-4 h-4"
            viewBox="0 0 24 24"
          >
            <path d="M6 9l6 6 6-6"></path>
          </svg> */}
        </span>
      </div>
    </div>
  )

  return simple

  //   return (
  //     <div tw="flex-auto flex flex-col items-center h-64">
  //       <div tw="flex flex-col items-center relative">
  //         <div tw="w-full ">
  //           <div tw="my-2 bg-white p-1 flex border border-gray-200 rounded ">
  //             <div tw="flex flex-auto flex-wrap"></div>
  //             <input
  //               readOnly
  //               value="Javascript"
  //               tw="p-1 px-2 appearance-none outline-none w-full text-gray-800 "
  //             />
  //             <div>
  //               <button tw="cursor-pointer w-6 h-full flex items-center text-gray-400 outline-none focus:outline-none">
  //                 <XIcon />
  //               </button>
  //             </div>
  //             <div tw="text-gray-300 w-8 py-1 pl-2 pr-1 border-l flex items-center border-gray-200">
  //               <button tw="cursor-pointer w-6 h-6 text-gray-600 outline-none focus:outline-none">
  //                 <ChevronUpIcon />
  //               </button>
  //             </div>
  //           </div>
  //         </div>
  //         <div tw="absolute shadow z-40 w-full left-0 rounded overflow-y-auto">
  //           <div tw="flex flex-col w-full">
  //             <div tw="cursor-pointer w-full border-gray-100 rounded-t border-b hover:bg-teal-100">
  //               <div tw="flex w-full items-center p-2 pl-2 border-transparent bg-white border-l-2 relative hover:bg-teal-600 hover:text-teal-100 hover:border-teal-600">
  //                 <div tw="w-full items-center flex">
  //                   <div tw="mx-2 leading-6  ">Python </div>
  //                 </div>
  //               </div>
  //             </div>
  //             <div
  //               tw="cursor-pointer w-full border-gray-100 border-b hover:bg-teal-100 "
  //             >
  //               <div tw="flex w-full items-center p-2 pl-2 border-transparent bg-white border-l-2 relative hover:bg-teal-600 hover:text-teal-100 border-teal-600">
  //                 <div tw="w-full items-center flex">
  //                   <div tw="mx-2 leading-6  ">Javascript </div>
  //                 </div>
  //               </div>
  //             </div>
  //             <div
  //               tw="cursor-pointer w-full border-gray-100 rounded-b hover:bg-teal-100 "
  //             >
  //               <div tw="flex w-full items-center p-2 pl-2 border-transparent bg-white border-l-2 relative  hover:bg-teal-600 hover:text-teal-100 hover:border-teal-600">
  //                 <div tw="w-full items-center flex">
  //                   <div tw="mx-2 leading-6  ">Ruby </div>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   )
}

export default Select
