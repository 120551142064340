const prodShippingRates = [
  {
    title: "Self Pick Up (free)",
    id: "shr_1Ia5dFDmt915koXexwYyOEb5",
    price: 0,
  },
  {
    title: "First Class ($7.00)",
    id: "shr_1IZrZNDmt915koXeZRuFKyTm",
    price: 700,
  },
  {
    title: "Priority Mail ($15.50)",
    id: "shr_1IZrNGDmt915koXea6jKlT0z",
    price: 1550,
  },
]

const testShippingRates = [
  {
    title: "Self Pick Up (free)",
    id: "shr_1IcSUcDmt915koXeNmRRywjK",
    price: 0,
  },
]

export const shippingRates =
  process.env.NODE_ENV === "production" ? prodShippingRates : testShippingRates
