import React, { useContext, useState } from "react"
import styled from "styled-components"
import tw from "twin.macro"
import { css } from "styled-components/macro" //eslint-disable-line

import { CartContext } from "./CartProvider"
import Checkout from "./Checkout"
import CartItem from "./CartItem"
import CartIcon from "react-feather/dist/icons/shopping-cart"
import ChevronIcon from "react-feather/dist/icons/chevron-right"
import { PrimaryButton } from "./treact/misc/Buttons.js"
import Select from "./core/Select"

import { shippingRates } from "../data/shippingRates"
import { taxRate } from "../data/taxRate"

const CartButton = styled(PrimaryButton)`
  ${tw`text-lg font-semibold lg:text-sm
  tracking-wide
  px-3 py-0 sm:px-5 sm:py-3 rounded-full`}
`

const Sidebar = tw.div`
  border-l-0 border-beige-300 shadow-2xl fixed right-0 top-0 h-screen overflow-y-auto
  p-4 sm:p-8 bg-beige-100 max-w-128 w-full z-30
`

const Header = tw.div`
  flex justify-between items-center pb-4
`

const Cart = ({ className }) => {
  const {
    cart,
    count,
    total,
    mode,
    toggle,
    grandTotal,
    setShippingMethod,
    shipping,
  } = useContext(CartContext)
  const onShippmentChange = e => {
    setShippingMethod(e.target.value)
  }
  return (
    <>
      {count > 0 && (
        <CartButton className={className} onClick={() => toggle()}>
          <CartIcon size="18" tw="hidden sm:inline-block mr-3" />
          {count}
        </CartButton>
      )}
      <Sidebar css={[!mode && tw`hidden`]}>
        <Header>
          <button
            tw="font-bold rounded-full px-2 py-2 hocus:bg-beige-200 hocus:text-beige-500"
            onClick={() => toggle()}
          >
            <ChevronIcon />
          </button>
          <h1 tw="text-2xl font-bold">Cart</h1>
          <span></span>
        </Header>

        {count > 0 &&
          cart.map(([price, quantity, notes]) => (
            <CartItem
              key={price.id}
              price={price}
              quantity={quantity}
              notes={notes}
            />
          ))}
        {count === 0 && (
          <div tw="text-2xl py-10 text-gray-500 text-center">
            No items in cart.
          </div>
        )}
        {count > 0 && (
          <>
            <div tw="flex justify-between pt-3">
              <span>Subtotal</span>
              <span>${total / 100}</span>
            </div>
            <Select
              tw="justify-between pt-3"
              label="Shipping"
              items={shippingRates}
              value={shipping}
              onChange={onShippmentChange}
            />
            <div tw="flex justify-between pt-3">
              <span>Sales tax</span>
              <span>${Math.ceil(total * taxRate) / 100}</span>
            </div>
            <div tw="flex justify-between mt-6 pt-3 border-t-default border-gray-500 font-bold text-xl">
              <span>Total</span>
              <span>${Math.ceil(grandTotal) / 100}</span>
            </div>
            <div tw="flex justify-end pt-5">
              {/* <span>{shippment}</span> */}
              <Checkout />
            </div>
          </>
        )}
      </Sidebar>
    </>
  )
}

export default Cart
