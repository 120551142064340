import React, { useContext } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import tw from "twin.macro"
import { css } from "styled-components/macro" //eslint-disable-line
import DeleteIcon from "react-feather/dist/icons/x-circle"

import { CartContext } from "./CartProvider"

const ItemContainer = tw.div`flex mx-2 pt-2 mb-4 relative`

const CartItem = ({ price, quantity, notes }) => {
  const { remove } = useContext(CartContext)
  return (
    <>
      <ItemContainer key={price.id}>
        <Link to={`/buy/${price.product.slug}`} tw="mr-4">
          <img
            tw="w-32 min-h-32 object-contain"
            src={price.image || price.product.images[0]}
            alt={price.product.name}
          />
        </Link>
        <div tw="flex-1">
          <Link to={`/buy/${price.product.slug}`}>
            <div tw="font-bold text-lg pb-2 mr-8">
              {price.product.name}
            </div>
            <div>{price.nickname}</div>
          </Link>
          <button
            tw="absolute p-2 right-0 top-0 rounded-default text-red-600 hocus:bg-red-200 hocus:text-red-700"
            onClick={() => {
              remove(price.id)
            }}
          >
            <DeleteIcon size="18" />
          </button>
          <div tw="flex justify-between">
            <span>
              ${price.unit_amount / 100} &times; {quantity}
            </span>
            <strong>${(price.unit_amount / 100) * quantity}</strong>
          </div>
          <div tw="text-sm text-gray-600 mt-2 whitespace-pre-line">
            <em>{notes}</em>
          </div>
        </div>
      </ItemContainer>
    </>
  )
}

CartItem.propTypes = {
  price: PropTypes.object.isRequired,
  quantity: PropTypes.number.isRequired,
}

export default CartItem
